import React, {useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Layout from "../components/Layout";
import GoogleMapReact from 'google-map-react';
import NewsCard from "../components/NewsCard";
import NewsService from '../services/api/news'
import Flow from "../components/Flow";
import HomeCard from "../components/HomeCard";
import EventService from "../services/api/events";
import AgendaCard from "../components/AgendaCard";
import Weather from "../components/Weather";
import history from "../history";

function Home() {

    const [news, setNews] = useState([]);
    const [newsLoading, setNewsLoading] = useState(false);
    const [newsError, setNewsError] = useState(false);
    const [newsPage, setNewsPage] = useState(1);
    const [hasMoreNews, setHasMoreNews] = useState(true);

    const [agenda, setAgenda] = useState([]);
    const [agendaLoading, setAgendaLoading] = useState(false);
    const [agendaPage, setAgendaPage] = useState(1);
    const [agendaError, setAgendaError] = useState(false);
    const [hasMoreAgenda, setHasMoreAgenda] = useState(true);

    const loadMoreAgenda = () => {
        if (agendaLoading === true || hasMoreAgenda === false || agendaError === true)
            return;
        setAgendaLoading(true);
        EventService
            .fetchGroup(agendaPage)
            .then(response => {
                if (response.data.length === 0) {
                    setHasMoreAgenda(false);
                } else {
                    let current_date = Date.now();
                    let agendaCards = response.data.map(function (events) {
                        let end_date = new Date(events.end_date);
                        let start_date = new Date(events.start_date);
                        if (start_date.getTime() < current_date && end_date.getTime() > current_date) {
                            return (<AgendaCard
                                key={events.id}
                                id={events.id}
                                title={events.title}
                                date={events.date}
                                type={"events"}
                                dateEnd={events.date_end}
                                displayDateEnd={events.display_date_end}
                            />)
                        }
                        return null
                    });
                    setAgenda([...agenda, agendaCards]);
                    setAgendaPage(newsPage + 1);
                    setAgendaLoading(false);
                }
            })
            .catch(error => {
                console.debug(error);
                setAgendaLoading(false);
                setAgendaError(true);
            });
    }

    const loadMoreNews = () => {
        if (newsLoading === true || hasMoreNews === false || newsError === true)
            return;
        setNewsLoading(true);
        NewsService
            .fetchGroup(newsPage)
            .then(response => {
                if (response.data.length === 0) {
                    setHasMoreNews(false);
                } else {
                    let current_date = Date.now();
                    let newsCards = response.data.map(function (news) {
                        let online_date = new Date(news.online_date);
                        let end_date = new Date(news.end_date);
                        if (online_date.getTime() < current_date && end_date.getTime() > current_date) {
                            return (<NewsCard key={news.id} id={news.id} title={news.title} spoil={news.spoil}
                                              image={news.image} type={"news"}/>)
                        }
                        return null
                    });
                    setNews([...news, newsCards]);
                    setNewsPage(newsPage + 1);
                    setNewsLoading(false);
                }
            })
            .catch(error => {
                console.debug(error);
                setNewsLoading(false);
                setNewsError(true);
            });
    };

    const handleUrgence = () => {
        history.push('/page/urgences')
    };

    return (
        <Layout>
            <Container fluid>
                <Row className="p-2">
                    <Col xs={12} sm={{span: 10, offset: 1}} md={{span: 10, offset: 1}} lg={{span: 10, offset: 1}}
                         xl={{span: 2, offset: 2}}>
                        <HomeCard title={"Nos coordonnées"}>
                            <div className="p-2 text-center font-color-2">
                                <img className="m-2" src={process.env.PUBLIC_URL + '/images/mairie.jpg'} alt=""/>
                                <p>99 Chemin de l'Anglaise<br/>
                                    62340 Bonningues-les-Calais<br/>
                                    Tél : 03 21 82 16 29<br/>
                                    Fax : 03 21 82 18 01<br/>
                                    Mail: <a
                                        href={"mailto:mairie@bonningueslescalais.fr"}>mairie@bonningueslescalais.fr</a><br/>
                                    <br/>
                                    <b>Horaires d'ouverture public:</b><br/>
                                    Lundi : de 8h à 12h<br/>
                                    Mardi : de 8h à 12h<br/>
                                    Mercredi : de 8h à 12h<br/>
                                    Jeudi : de 8h à 12h<br/>
                                    Vendredi : 8h à 12h<br/>
                                </p>
                            </div>
                        </HomeCard>
                        <div onClick={() => handleUrgence()} className="cursor-pointer">
                            <HomeCard title={"URGENCES"} titleStyle={"pb-3 mb-0 font-weight-bold"}/>
                        </div>
                        <HomeCard>
                            <div className="map">
                                <GoogleMapReact
                                    bootstrapURLKeys={{key: "AIzaSyAtSuhku27RQceuTq59tbv7NjU_pSQwHxQ"}}
                                    defaultCenter={{
                                        lat: 50.890019,
                                        lng: 1.7726848
                                    }}
                                    defaultZoom={11}
                                >
                                </GoogleMapReact>
                            </div>
                        </HomeCard>
                    </Col>
                    <Col xs={12} sm={{span: 10, offset: 1}} md={{span: 10, offset: 1}} lg={{span: 10, offset: 1}}
                         xl={{span: 4, offset: 0}}>
                        <HomeCard
                            title={"Actualités"}
                            childrenStyle={"pt-2 link-remove-style"}
                        >
                            <Flow
                                pageStart={newsPage}
                                loadMorePage={loadMoreNews}
                                hasMorePage={hasMoreNews}
                                content={news}
                                height={"news-container font-color-2"}
                            />
                        </HomeCard>
                    </Col>
                    <Col xs={12} sm={{span: 10, offset: 1}} md={{span: 10, offset: 1}} lg={{span: 10, offset: 1}}
                         xl={{span: 2, offset: 0}}>
                        <HomeCard
                            title={"Dates à retenir"}
                            childrenStyle={"pt-2 link-remove-style"}
                        >
                            <Flow
                                pageStart={agendaPage}
                                loadMorePage={loadMoreAgenda}
                                hasMorePage={hasMoreAgenda}
                                content={agenda}
                                height={"agenda-container font-color-2"}
                            />
                        </HomeCard>
                        <HomeCard title={"Météo"}>
                            <div className="font-color-2 text-center p-2">
                                <Weather/>
                            </div>
                        </HomeCard>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default Home;
