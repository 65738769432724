import React from "react";
import { Router, Route } from "react-router-dom";
import history from "./history";
import Home from "./pages/home";
import More from "./pages/more";
import Pictures from "./pages/pictures";
import Report from "./pages/comptesRendus";
import NewsLetter from "./pages/NewsLetter";
import Association from "./pages/association";
import Page from "./pages/pages";
import Albums from "./pages/albums";
import Company from "./pages/company";
import Dechets from "./pages/dechets";

const AppRoute = () => {
  return (
    <Router history={history}>
      <Route exact path="/" component={Home} />
      <Route path="/more" component={More} />
      <Route exact path="/albums" component={Albums} />
      <Route path="/albums/:id" component={Pictures} />
      <Route path="/comptes-rendus" component={Report} />
      <Route path="/bulletins" component={NewsLetter} />
      <Route path="/association" component={Association} />
      <Route path="/entreprise" component={Company} />
      <Route path="/ramassage-des-dechets" component={Dechets} />
      <Route path="/page/:slug" component={Page} />
    </Router>
  );
};

export default AppRoute;
