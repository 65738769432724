import React, { useState } from "react";
import Flow from "../components/Flow";
import ReportCard from "../components/ReportCard";
import LayoutPage from "../components/LayoutPage";
import MunicipalNewsLetter from "../services/api/municipalNewsLetter";
import { PICTURE_URL } from "../shared/constants";

function NewsLetter() {
  const [newsLetter, setNewsLetter] = useState([]);
  const [newsLetterLoading, setNewsLetterLoading] = useState(false);
  const [newsLetterError, setNewsLetterError] = useState(false);
  const [hasMorePage, sethasMorePage] = useState(true);
  const [NewsLetterPage] = useState(1);

  const loadMoreNewsLetter = () => {
    if (newsLetterLoading === true || newsLetterError === true) return;
    setNewsLetterLoading(true);
    MunicipalNewsLetter.fetchGroup(NewsLetterPage)
      .then((response) => {
        const result = response.map((news) => {
          let title;
          let reportLink;
          if (news.report.includes("http")) {
            title = "Bulletin municipal du ";
            reportLink = news.report;
          } else {
            title = "Bonningues mag du ";
            reportLink = `${PICTURE_URL}/${news.report}.pdf`;
          }
          return (
            <ReportCard
              key={news.id}
              date={news.date}
              report={reportLink}
              content={title}
            />
          );
        });
        setNewsLetter([result]);
      })
      .catch((error) => {
        console.log(error);
        setNewsLetterError(true);
      })
      .finally(() => {
        setNewsLetterLoading(false);
        sethasMorePage(false);
      });
  };

  return (
    <LayoutPage title={"Bulletins municipaux"}>
      <Flow
        pageStart={1}
        loadMorePage={loadMoreNewsLetter}
        hasMorePage={hasMorePage}
        content={newsLetter}
        height={"report-container font-color-2"}
      />
    </LayoutPage>
  );
}

export default NewsLetter;
