import React, { useEffect } from 'react';
import LayoutPage from "../components/LayoutPage";

function Dechets() {

  useEffect(() => {
    var loadScript = function () {
      window['PublidataWidgetObject']= 'pw';
      window['pw'] = function () { (window['pw'].q = window['pw'].q || []).push(arguments) };
      const js = document.createElement('script');
      const fjs = document.getElementsByTagName('script')[0];
      js.id = 'pw';
      js.src = 'https://cdn.publidata.io/widget.js';
      js.async = 1;
      fjs.parentNode.insertBefore(js, fjs);
      window.pw('init', 'QG7jPtQ5Lg')
    }
    loadScript();
  }, [])

    return (
        <LayoutPage
            title={"Ramassage des déchets"}
        >
            <div id='widget-container'><a target='_blank' href='https://widgets.publidata.io/QG7jPtQ5Lg'>Accéder au widget de collecte des déchets</a></div>
        </LayoutPage>
    );
};

export default Dechets;

